<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation']"
            ref="list"
			:where='where'
        >
            <template slot="left_btn">
                <a-button
                    class="float_left"
                    v-auth="'change'"
                    @click="add()"
                    type="primary"
                    >添加模板</a-button
                >
            </template>
            <template slot="operation" slot-scope="data">
                <a  @click="edit(data.record)">编辑</a>
                <a-divider  type="vertical" />
                <a  @click="change_status(data.record)">{{
                    data.record.status == 1 ? "禁用" : "启用"
                }}</a>
                <a-divider type="vertical" />
                <a-popconfirm
                    title="您确定要删除吗?"
                    @confirm="del(data.record)"
                >   <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getContractTemplateList, delContractTemplate, changeContractStauts, getCostType } from "@/api/cost";

let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "合约模板名称",
        dataIndex: "title",
    },
    
	{
		title: "是否为默认模板",
		dataIndex: "default",
	},
    {
        title: "状态",
        dataIndex: "status_name",
    },
    {
        title: "操作",
		width: 160,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: getContractTemplateList,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
			where:{type:2},
            form_data_seo: {
                list: [
                    
                    {
                        type: "text",
                        name: "keyword",
                        title: "模板名称",
                        placeholder: '请输入模板名称',
                        options: {

                        }
                    },
                    {
                        type: 'select',
                        name: 'status',
                        title: '启用状态',
                        list: [
                            ...this.$config.status_all,
                            {
                                key: 1,
                                value: "启用"
                            },
                            {
                                key: 0,
                                value: "禁用"
                            }
                        ],
                        options: {},
                    },
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
      
		
    },
    methods: {
		add(){
			this.$router.push('template_edit')
		},
        edit(data) {
            this.$router.push('template_edit?id=' + data.id)
        },
        change_status(data) {
            changeContractStauts({
                data: {
                    id: data.id,
                    status: data.status ? 0 : 1
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        del(data) {
            delContractTemplate({
                data: {
                    id: data.id
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
            })
        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>